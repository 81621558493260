.fav {
  margin: 7rem auto 3rem;
  color: #b398ce; 
  text-align: center; 
  font-size: 30px; 
  font-weight: bold; 
  letter-spacing: 2px;
}

.favorites-container {
  padding: 10px;
  margin: 2rem;
  align-items: center;
}


.favorites-list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  min-height: 200px;  
}

.favorites-text {
  font-size: 18px;
  text-align: center;
  flex: 1; 
}

.favorite-card {
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  
  transition: transform 0.2s;
  flex: 0 1 calc(14% - 15px); 
  margin-bottom: 10px; 
  display: flex;
  flex-direction: column;
}

.favorite-image {
  width: 100%;
  height: auto;
  flex-grow: 1; 
}

.favorite-item-name {
  text-align: start;
  padding: 10px;
  background-color: rgba(179, 153, 206, 0.8);
  width: 100%;
  flex-shrink: 0;
  font-weight: bold;
  font-size: 16px;
}

.icon-container {
  position: absolute;
  top: 10px; 
  right: 10px; 
  display: flex;
  flex-direction: column; 
  gap: 10px; 
}

.icon-circle {
  border-radius: 50%; 
  padding: 10px; 
  display: flex;
  align-items: center;
  justify-content: center; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); 
}

.cart-icon {
  background-color: #b398ce; 
  color: white;
}

.heart-icon {
  background-color: beige; 
  color: purple; 
}

@media (max-width: 1200px) {
  .favorite-card {
    flex: 0 1 calc(20% - 15px); 
  }
}

@media (max-width: 992px) {
  .favorite-card {
    flex: 0 1 calc(25% - 15px); 
  }
}

@media (max-width: 768px) {
  .favorite-card {
    flex: 0 1 calc(33% - 15px); 
  }
}

@media (max-width: 576px) {
  .favorite-card {
    flex: 0 1 calc(48% - 15px); 
  }
}

@media (max-width: 400px) {
  .favorite-card {
    flex: 0 1 calc(50% - 10px); 
  }
}

