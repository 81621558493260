.aboutus {
    background-color: #D9D9D9;
    font-family: 'Arial', sans-serif;
    line-height: 1.3;
}

.about-us-container {
    padding: 4rem;
    margin-top: 5.6rem;
    min-height: 100vh; 
    height: 100vh; 
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: black;
    box-sizing: border-box; 
}

.about-us-container h1 {
    padding-top: 5rem;
    padding-left: 17rem;
    text-align: start;
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
    max-width: 80%;
}

.about-us-container p {
    font-size: 1.4rem;
    width: 50%;
    margin-left: 24rem;
    text-align: justify;
}

.whatis-container {
    background-color: #B398CEC2;
    min-height: 100vh; 
    height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: black;
    box-sizing: border-box; 
}

.whatis-container p {
    font-size: 1.4rem;
    width: 50%;
    align-self: flex-end;
    padding-right: 3.9rem;
    text-align: justify;
}

.ribbon {
    content: '';
    display: block;
    width: 18%;
    height: 3.8rem;
    background-color: #412D41;
    margin-bottom: 40px;
    margin-top: 20px;
    margin-left: 28.5rem;
    align-self: flex-end;
}

.why-container {
    display: flex;
    justify-content: space-between; 
    align-items: flex-start; 
    height: auto;
}

.text-container {
    width: 50%; 
    text-align: start;
    padding-top: 5rem;
    padding: 3rem;
    height: auto;
}

.why-container h1 {
    font-size: 2.5rem;
    font-weight: 700;
    max-width: 80%;
    padding: 3rem;
}

.why-container p {
    font-size: 1.4rem;
    margin-bottom: 1rem; 
}

.image-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end; 
    align-items: center; 
}

.image-container img {
    height: 100vh;
    width: 60vw; 
}

.ourstory {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 7rem 3rem;
    height: 100vh; 
  }
  
  .ourstory .our,
  .ourstory .story {
    color: white;
    text-align: start;
    font-size: 2.7rem;
    font-weight: 700;
    margin: 0; 
  }
  
  .ourstory .story {
    color: #B398CE;
    padding-left: 3rem;
    margin-top: -0.5rem; 
    margin-bottom: 2rem;
  }
  
  .line {
    display: block;
    width: 50%;
    height: 5px;
    background-color: #412D41;
    margin-left: 15rem;
    text-decoration: underline;
  }

  .ourstory p {
    font-size: 1.4rem;
    align-self: flex-end;
    padding-right: 3.9rem;
    text-align: justify;
  }
  
  
@media (max-width: 1024px) {
    .about-us-container, .whatis-container {
        height: auto; 
        min-height: auto;
        padding-bottom: 2rem; 
    }

    .ribbon {
        display: none;  
    }

    .ourstory {
        margin: 1rem 2rem; 
      }
    
      .ourstory .story {
        padding-left: 3rem; 
      }
    
      .line {
        width: 60%; 
        margin-left: auto;
        margin-right: auto;
      }
    
      .ourstory p {
        font-size: 1.3rem;
        padding-right: 2rem; 
      }
}

@media (max-width: 768px) {
    .about-us-container {
        padding: 2rem;
        margin-top: 3rem;
        height: auto;
        min-height: auto;
    }

    .about-us-container h1 {
        padding-top: 2rem;
        padding-left: 2rem;
        font-size: 2rem;
        text-align: center;
        margin-bottom: 1.5rem;
    }

    .about-us-container p {
        width: 80%;
        margin-left: 0;
        font-size: 1.2rem;
    }

    .whatis-container p {
        width: 80%;  
        align-self: center;  
    }

    .ribbon {
        display: none;
    }

    .ourstory {
        margin: 1rem; 
      }
    
      .ourstory .story {
        padding-left: 3rem;
      }
    
      .line {
        display: none;
      }
    
      .ourstory p {
        font-size: 1.2rem;
        padding-right: 1.5rem; 
      }
    }


@media (max-width: 480px) {
    .about-us-container h1 {
        font-size: 1.8rem;
        padding-left: 1rem;
    }

    .about-us-container p {
        width: 90%;
        font-size: 1rem;
        line-height: 1.4;
        text-align: start;
    }

    .whatis-container p {
        width: 90%;
        font-size: 1rem;
        line-height: 1.4;
        text-align: start;
    }

    .ribbon {
        display: none;
    }

    .ourstory {
        margin: 1rem 1rem; 
      }
    
      .ourstory .story {
        padding-left: 3rem;
      }
    
      .line {
        display: none;
      }
    
      .ourstory p {
        font-size: 1rem; 
        line-height: 1.4;
        padding-right: 1rem;
        text-align: start;
      }
}
