.container1 {
    position: fixed;
    bottom: 200px;
    right: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 6px;
  }
  
  .download-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .socialicon {
    width: 35px;
    height: 35px;
    object-fit: cover;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s ease-in-out;
  }
  
  .socialicon:hover {
    transform: scale(1.15);
  }
  
  .toolkit {
    display: block;
    font-size: 0.7rem;
    color: white;
    background-color: #6a1b9a;
    padding: 3px 8px;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 3px;
    position: relative;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-out, visibility 0s 0.5s;
  }
  
  .toolkit.show {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-in, visibility 0s 0s;
  }
  
  .toolkit::before {
    content: "";
    position: absolute;
    bottom: -11px;
    left: 90%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: #6a1b9a transparent transparent transparent;
  }
  
  .container1:hover .toolkit {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-in, visibility 0s 0s;
  }
  
  .highlight {
    border: 3px solid purple;
    background-color: #c8a5db;
    border-radius: 5px;
    padding: 3px;
    box-shadow: 0 0 15px rgba(175, 102, 234, 0.6);
  }
  
  .download-section img {
    width: 40px;
    height: 40px;
  }
  
  @media (max-width: 1200px) {
    .container1 {
      bottom: 150px; 
      right: 10px;
    }
  
    .toolkit {
      font-size: 0.65rem;
      padding: 4px 9px;
      margin-bottom: 5px;
    }
  
    .socialicon {
      width: 38px;
      height: 38px;
    }
  }
  
  @media (max-width: 768px) {
    .container1 {
      bottom: 15px;
      right: 15px;
      gap: 12px;
      flex-direction: column;
      align-items: center;
    }
  
    .toolkit {
      font-size: 0.6rem;
      padding: 5px 10px;
      margin-bottom: 5px;
    }
  
    .socialicon {
      width: 40px;
      height: 40px;
    }
  }
  
  @media (max-width: 480px) {
    .container1 {
      bottom: 10px;
      right: 50%;
      transform: translateX(50%);
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }
  
    .toolkit {
      display: none;
    }
  
    .socialicon {
      width: 35px;
      height: 35px;
    }
  }
  
  @media (min-width: 1600px) {
    .container1 {
      bottom: 400px;
    }
  }
  
  @media (max-width: 375px) {
    .container1 {
      bottom: 10px;
      right: 50%;
      transform: translateX(50%);
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }
  
    .toolkit {
      display: none;
    }
  
    .socialicon {
      width: 35px;
      height: 35px;
    }
  }
  