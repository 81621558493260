.downloadButton {
  color: #412d41;
  background-color: rgb(179, 152, 206);
  stroke: none;
  border: none;
  font-weight: 600;
  padding: 0.5em 1em;
  transition: border-radius 0.4s, box-shadow 0.4s, color 0.4s,
    background-color 0.4s;
}

.downloadButton:hover {
  color: white;
  background-color: #3d1930;
  border-radius: 5px;
  box-shadow: 3px 3px #888888;
}

* {
  /* outline: 1px solid red; */
}
